import OaApplyCard from './OaApplyCard.vue';
import {ref, reactive, toRefs, computed, defineComponent,onBeforeMount, onMounted, getCurrentInstance, watch, provide,nextTick} from 'vue';
import OaApplyListUtil,{IOaApplyListDataObj} from './oaApplyListUtil';
import language from './oaApplyLanguage'
export default defineComponent ({
    name: 'OaApplyList',
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        proxy.addLangProps(language);
        let dataObj:IOaApplyListDataObj=reactive<IOaApplyListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            pageList: {
                cardFrom:'OaApplyList',
                queryParam: {},
                modelComp: OaApplyCard,
                modelMethod: utils.OrderProviderApi.buildUrl('/oaApply/pageData')
            },
            otherParams:{
                oaZxbData:[],//中信保账户下拉数据
                customerData:[],//客户下拉数据
            },
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new OaApplyListUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed------------
        //---------------------------computed------------
        //下拉选择
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'oaZxbId'==params.comboId){
                    return dataObj.otherParams.oaZxbData;
                }
                if(params && 'customerId'==params.comboId){
                    return dataObj.otherParams.customerData;
                }
            }
        })
        const formatPageInfo=(options:any)=>{
            return options;
        }

        return{
            ...toRefs(dataObj),formatPageInfo,comboSelect
        }
    }
});